<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item prop="name" label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item prop="remark" label="备注">
        <el-input v-model="form.remark"></el-input>
      </el-form-item>
      <el-form-item label="菜单权限">
        <el-tree :data="treeData" :props="{ label: 'name', children: 'children' }" node-key="id" show-checkbox
          check-strictly @check="treeCheck">
        </el-tree>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          name: '',
          remark: '',
          menuIdList: []
        },
        rules: {
          name: [{
            required: true,
            message: '必填不能为空',
            trigger: 'blur'
          }]
        },
        treeData: []
      }
    },
    created() {
      this.getTreeData();
    },
    methods: {
      close() {
        this.$refs.form.resetFields()
        this.$emit('close', true)
      },
      onSubmit() {
        this.$refs.form.validate((valid) => {
          console.log(valid)
          if (!valid) {
            return false;
          }
          this.$http.post('/role', this.form).then(({
            data: res
          }) => {
            let {
              code,
              msg,
              data
            } = res
            console.log(code, msg, data)

            if (code === 0) {
              this.$message.success('新增成功')
              this.close();
            }

            if (code !== 0) {
              this.$message.error(msg)
            }
          })
        })
      },
      getTreeData() {
        this.$http.get('/role/menu', {
          params: {}
        }).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)

          if (code === 0 && data) {

            //排序
            data.sort((a, b) => a.sort - b.sort)

            //关联子节点
            data.forEach(item => {
              item.children = data.filter(item2 => item2.pid === item.id)
            })

            //获取根节点
            let root = data.filter(item => item.pid === "0")

            let records = root
            this.treeData = records && records.length ? records : []
          }

          if (code !== 0) {
            this.$message.error(msg)
          }
        })
      },
      treeCheck(current, checkd) {
        console.log(current, current)
        console.log(checkd.checkedKeys)
        this.form.menuIdList = checkd.checkedKeys

      }
    }
  }
</script>

<style>
</style>
