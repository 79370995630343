<template>
  <div>
    <el-card>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="addDialogVisible=true">新增</el-button>
        </el-form-item>
      </el-form>

      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%;" border>
        <el-table-column header-align="center" align="center" prop="name" label="名称">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="remark" label="备注">
        </el-table-column>
        <el-table-column header-align="center" align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="updateHandle(scope.row.id)">修改</el-button>
            <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :visible.sync="addDialogVisible" title="新增">
      <add v-if="addDialogVisible" @close="addClose"></add>
    </el-dialog>

    <el-dialog :visible.sync="updateDialogVisible" title="修改">
      <update v-if="updateDialogVisible" :id="updateId" @close="updateClose"></update>
    </el-dialog>
  </div>
</template>

<script>
  import add from './add'
  import update from './update'
  export default {
    components: {
      add,
      update
    },
    data() {
      return {
        tableLoading: false,
        tableData: [],
        addDialogVisible: false,
        updateDialogVisible: false,
        updateId: null
      }
    },
    activated() {
      this.getTableData()
    },
    methods: {
      getTableData() {
        this.tableLoading = true
        this.$http.get('/role', {
          params: {}
        }).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)
          if (code === 0 && data) {

            let records = data
            this.tableData = records && records.length ? records : []
          }
          if (code !== 0) {
            this.$message.error(msg)
          }
          this.tableLoading = false
        })
      },
      /**
       * 新增功能关闭事件
       */
      addClose() {
        this.addDialogVisible = false
        this.getTableData()
      },
      /**
       * 修改功能关闭事件
       */
      updateClose() {
        this.updateDialogVisible = false
        this.getTableData()
      },
      updateHandle(id) {
        console.log(id)
        this.updateId = id
        this.updateDialogVisible = true
      },
      deleteHandle(id) {
        this.$http.delete('/role/' + id).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)

          if (code === 0) {
            this.$message.success('删除成功')
            this.getTableData()
          }

          if (code !== 0) {
            this.$message.error(msg)
          }
        })
      }
    }
  }
</script>

<style>
</style>
